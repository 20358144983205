import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  UPDATE_COLLECTION_SLOT_MUTATION,
  CREATE_COLLECTION_SLOT_MUTATION,
  DELETE_COLLECTION_SLOT_MUTATUIN,
  UPDATE_COLLECTION_VISIBLE_STATES_MUTATION,
  UPDATE_PRODUCT_COLLECTION,
  DELETE_COLLECTION_MUTATION,
  CREATE_PRODUCT_COLLECTION,
  UPDATE_THEME_COLLECTION_MUTATION,
  CREATE_THEME_COLLECTION_MUTATION,
} from '../queries/mutation';
import {
  CollectionSlot,
  CollectionSlotCreateParam,
  CollectionSlotUpdateParam,
} from '../model/slot';
import {
  Collection,
  CollectionVisibility,
  CollectionSuccessStatus,
  ProductCollectionUpdateParam,
  ProductCollectionCreateParam,
  ThemeCollectionUpdateParam,
  ThemeCollectionCreateParam,
} from '../model/collection';

export class CollectionService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }
  async updateCollectionSlot(
    param: CollectionSlotUpdateParam
  ): Promise<CollectionSlot> {
    this.validateCollectionSlotUpdate(param);
    const result = await this.apollo.mutate<{
      updateCollectionSlot: CollectionSlot;
    }>({
      mutation: UPDATE_COLLECTION_SLOT_MUTATION,
      variables: {
        param: param,
      },
    });
    if (!result || !result.data) {
      throw new Error(`슬롯 저장 결과값이 없습니다.`);
    }
    return result.data.updateCollectionSlot;
  }
  private validateCollectionSlotUpdate(param: CollectionSlotUpdateParam): void {
    console.log('todo validate');
  }

  async createCollectionSlot(
    param: CollectionSlotCreateParam
  ): Promise<CollectionSlot> {
    this.validateCollectionSlotCreate(param);
    const result = await this.apollo.mutate<{
      createCollectionSlot: CollectionSlot;
    }>({
      mutation: CREATE_COLLECTION_SLOT_MUTATION,
      variables: {
        param: param,
      },
    });
    if (!result || !result.data) {
      throw new Error(`슬롯 저장 결과값이 없습니다.`);
    }
    return result.data.createCollectionSlot;
  }

  private validateCollectionSlotCreate(param: CollectionSlotCreateParam): void {
    console.log('todo create slot param validate');
  }

  async deleteCollectionSlot(id: string): Promise<void> {
    const result = await this.apollo.mutate<{
      createCollectionSlot: CollectionSlot;
    }>({
      mutation: DELETE_COLLECTION_SLOT_MUTATUIN,
      variables: {
        id: id,
      },
    });
    if (!result || !result.data) {
      throw new Error(`슬롯 삭제 결과값이 없습니다.`);
    }
  }

  async updateCollectionVisibleStates(
    slotId: string,
    params: CollectionVisibility[]
  ): Promise<CollectionSuccessStatus> {
    params.forEach(param => this.validateCollectionVisibility(param));
    const result = await this.apollo.mutate<{
      updateCollectionVisibleStates: CollectionSuccessStatus;
    }>({
      mutation: UPDATE_COLLECTION_VISIBLE_STATES_MUTATION,
      variables: {
        slotId: slotId,
        visibleStates: params,
      },
    });
    if (!result || !result.data) {
      throw new Error(`슬롯 저장 결과값이 없습니다.`);
    }
    return result.data.updateCollectionVisibleStates;
  }

  private validateCollectionVisibility(param: CollectionVisibility): void {
    console.log('todo visibility param validate');
  }

  async createProductCollection(
    param: ProductCollectionCreateParam
  ): Promise<Collection> {
    this.validateProductCollectionCreate(param);
    const result = await this.apollo.mutate<{
      createProductCollection: Collection;
    }>({
      mutation: CREATE_PRODUCT_COLLECTION,
      variables: {
        param: param,
      },
    });
    if (!result || !result.data) {
      throw new Error(`상품기획전 생성 결과값이 없습니다.`);
    }
    return result.data.createProductCollection;
  }

  private validateProductCollectionCreate(
    param: ProductCollectionCreateParam
  ): void {
    this.vaildatCollectionProductParam(param);
    console.log('todo validate validateProductCollectionCreate');
  }

  async updateProductCollection(
    param: ProductCollectionUpdateParam
  ): Promise<Collection> {
    this.validateProductCollectionUpdate(param);
    const result = await this.apollo.mutate<{
      updateProductCollection: Collection;
    }>({
      mutation: UPDATE_PRODUCT_COLLECTION,
      variables: {
        param: param,
      },
    });
    if (!result || !result.data) {
      throw new Error(`상품기획전 저장 결과값이 없습니다.`);
    }
    return result.data.updateProductCollection;
  }

  private validateProductCollectionUpdate(
    param: ProductCollectionUpdateParam
  ): void {
    this.vaildatCollectionProductParam(param);
    console.log('todo visibility param validate');
  }

  async updateThemeCollection(
    param: ThemeCollectionUpdateParam
  ): Promise<Collection> {
    this.validateThemeCollectionUpdate(param);

    const result = await this.apollo.mutate<{
      updateThemeCollection: Collection;
    }>({
      mutation: UPDATE_THEME_COLLECTION_MUTATION,
      variables: {
        param: param,
      },
    });
    if (!result || !result.data) {
      throw new Error(`상품기획전 저장 결과값이 없습니다.`);
    }
    return result.data.updateThemeCollection;
  }

  private vaildateThemeCollectionImageContentsParam(
    param: ThemeCollectionCreateParam | ThemeCollectionUpdateParam
  ): void {
    if (param.imageContents.length === 0) {
      throw new Error(`최소 1개의 이미지가 필요합니다.`);
    }
  }

  private vaildatCollectionProductParam(
    param:
      | ThemeCollectionCreateParam
      | ThemeCollectionUpdateParam
      | ProductCollectionCreateParam
      | ProductCollectionUpdateParam
  ): void {
    if (param.products.length === 0) {
      throw new Error(`최소 1개의 상품이 필요합니다.`);
    }
  }

  private validateThemeCollectionUpdate(
    param: ThemeCollectionUpdateParam
  ): void {
    this.vaildateThemeCollectionImageContentsParam(param);
    this.vaildatCollectionProductParam(param);
    console.log('todo visibility param validate');
  }

  async createThemeCollection(
    param: ThemeCollectionCreateParam
  ): Promise<Collection> {
    this.validateThemeCollectionCreate(param);
    const result = await this.apollo.mutate<{
      createThemeCollection: Collection;
    }>({
      mutation: CREATE_THEME_COLLECTION_MUTATION,
      variables: {
        param: param,
      },
    });
    if (!result || !result.data) {
      throw new Error(`상품기획전 저장 결과값이 없습니다.`);
    }
    return result.data.createThemeCollection;
  }

  private validateThemeCollectionCreate(
    param: ThemeCollectionCreateParam
  ): void {
    this.vaildateThemeCollectionImageContentsParam(param);
    this.vaildatCollectionProductParam(param);
    console.log('todo visibility param validate');
  }

  async deleteCollection(id: string): Promise<CollectionSuccessStatus> {
    const result = await this.apollo.mutate<{
      deleteCollection: CollectionSuccessStatus;
    }>({
      mutation: DELETE_COLLECTION_MUTATION,
      variables: {
        id: id,
      },
    });
    if (!result || !result.data) {
      throw new Error(`상품기획전 삭제 결과값이 없습니다.`);
    }
    return result.data.deleteCollection;
  }
}
