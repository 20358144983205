import gql from 'graphql-tag';

const COLLECTION_CONTENT_FRAGMENT = gql`
  fragment collectionThumbnailFields on Content {
    id
    thumbnail(fetchFormat: AUTO, crop: FILL, width: 60, devicePixelRatio: 2.0)
  }
`;

const PRODUCT_CONTENT_FRAGMENT = gql`
  fragment productThumbnailFields on Content {
    id
    thumbnail(fetchFormat: AUTO, crop: FILL, width: 60, devicePixelRatio: 2.0)
  }
`;

export const SLOT_QUERY = gql`
  query ($id: ID!) {
    collection {
      slot(id: $id) {
        id
        description
        maximumCountOfCollection
        collectionType
        conditions {
          id
          maxImageSize
          imageWidth
          imageHeight
          platform
          exposureType
        }
      }
    }
  }
`;
export const SLOTS_QUERY = gql`
  query ($type: CollectionType!) {
    collection {
      slots(type: $type) {
        id
        description
        maximumCountOfCollection
        collectionType
        countOfVisibleCollections
      }
    }
  }
`;

export const COLLECTIONS_QUERY = gql`
  query ($slotId: ID!, $page: Int, $pageSize: Int) {
    collection {
      collections(slotId: $slotId, page: $page, pageSize: $pageSize) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            id
            title
            description
            homeShowStartAt
            homeShowEndAt
            listShowStartAt
            listShowEndAt
            status
            isShownAtHome
            isRandomOrder
            seqNo
            slot {
              id
            }
            content {
              ...collectionThumbnailFields
            }
          }
        }
      }
    }
  }
  ${COLLECTION_CONTENT_FRAGMENT}
`;

export const COLLECTION_QUERY = gql`
  query (
    $id: ID!
    $page: Int
    $pageSize: Int
    $size: Int
    $filter: CollectionProductFilterInput
  ) {
    collection {
      collection(id: $id) {
        slot {
          conditions {
            id
            imageWidth
            imageHeight
            maxImageSize
            platform
          }
        }
        id
        title
        description
        displayProductImage
        displayMainImage
        displayProductSize
        listType
        homeShowStartAt
        homeShowEndAt
        listShowStartAt
        listShowEndAt
        status
        isShownAtHome
        isRandomOrder
        seqNo
        slot {
          id
        }
        images {
          platform
          content {
            id
            uri
          }
          exposureType
        }
        products(
          page: $page
          pageSize: $pageSize
          size: $size
          filter: $filter
        ) {
          totalCount
          edges {
            node {
              id
              title
              status
              isBenepia
              host {
                id
                name
              }
              headerContents {
                content {
                  ...productThumbnailFields
                }
              }
            }
            cursor
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${PRODUCT_CONTENT_FRAGMENT}
`;

export const LISTINGPRODUCTS_QUERY = gql`
  query ($page: Int, $size: Int, $filter: ListingProductFilterV4) {
    product {
      listingProductsV4(page: $page, size: $size, filter: $filter) {
        edges {
          node {
            id
            title
            status
            host {
              id
              name
            }
            headerContents {
              content {
                ...productThumbnailFields
              }
            }
          }
        }
      }
    }
  }
  ${PRODUCT_CONTENT_FRAGMENT}
`;

export const CURRENT_COLLECTIONS = gql`
  query (
    $slotId: ID!
    $platform: ClientPlatform
    $count: Int
    $onlyHome: Boolean
    $at: Instant
  ) {
    collection {
      currents(
        slotId: $slotId
        platform: $platform
        count: $count
        onlyHome: $onlyHome
        at: $at
      ) {
        id
        title
        description
        seqNo
        products {
          totalCount
          edges {
            node {
              id
            }
            cursor
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
        slot {
          id
        }
        homeShowStartAt
        homeShowEndAt
        listShowStartAt
        listShowEndAt
        status
        isShownAtHome
        content {
          id
        }
        images {
          platform
          content {
            id
          }
        }
      }
    }
  }
`;
